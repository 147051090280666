var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mb-0 pb-0"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){_vm.addScheduleDialog = true}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" "+_vm._s(("" + (_vm.$t('add'))))+" Schedule ")],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"me-2",attrs:{"append-icon":_vm.icons.mdiMagnify,"label":_vm.$t('search'),"single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"item-key":"id","headers":_vm.columnsSchedule,"loading":_vm.loading,"footer-props":{
        'items-per-page-text': _vm.$t('rowsPerPageLabel'),
      }},scopedSlots:_vm._u([{key:"item.messageReply",fn:function(ref){
      var item = ref.item;
return [_c('v-combobox',{attrs:{"chips":"","clearable":"","multiple":"","outlined":"","dense":"","deletable-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var phones = ref.phones;
      var item = ref.item;
      var select = ref.select;
      var selectedPhones = ref.selectedPhones;
return [_c('v-chip',_vm._b({attrs:{"clearable":"","input-value":selectedPhones,"close":"","rules":[_vm.validators.required]},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',phones,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}],null,true)})]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-switch',{model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}})]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditChatbot(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#F15858"}},[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Chatbot")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteProcess(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#F15858"}},[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete'))+" Chatbot")])])]}}],null,true)}),_c('v-dialog',{attrs:{"width":_vm.changeMessage ? '1000px' : '500px',"persistent":""},model:{value:(_vm.addScheduleDialog),callback:function ($$v) {_vm.addScheduleDialog=$$v},expression:"addScheduleDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 grey lighten-2 font-weigth-bold"},[_vm._v(" Add Auto Replay ")]),_c('v-divider'),_c('v-combobox',{attrs:{"chips":"","clearable":"","label":"Keyword","multiple":"","outlined":"","dense":"","deletable-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var phones = ref.phones;
      var item = ref.item;
      var select = ref.select;
      var selectedPhones = ref.selectedPhones;
return [_c('v-chip',_vm._b({attrs:{"clearable":"","input-value":selectedPhones,"close":"","rules":[_vm.validators.required]},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',phones,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('v-card-actions',{staticClass:"pt-0 pb-0"},[_c('v-select',{attrs:{"items":_vm.selectChannel,"return-object":"","item-text":"label","label":"Channel","outlined":"","dense":"","width":"400px"},model:{value:(_vm.selectedChannel),callback:function ($$v) {_vm.selectedChannel=$$v},expression:"selectedChannel"}})],1),_c('v-card-actions',{staticClass:"ml-n8 pb-0"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Time Start","prepend-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","dense":""},model:{value:(_vm.timeStart),callback:function ($$v) {_vm.timeStart=$$v},expression:"timeStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeStartDialog),callback:function ($$v) {_vm.timeStartDialog=$$v},expression:"timeStartDialog"}},[(_vm.timeStartDialog)?_c('v-time-picker',{attrs:{"format":"HH:mm","full-width":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var save = ref.save;
      var cancel = ref.cancel;
return [_c('v-btn',{staticClass:"mt-2 ml-n2",attrs:{"color":"primary","dense":""},on:{"click":_vm.saveTimeStart}},[_vm._v("Save")]),_c('v-btn',{staticClass:"error--text mt-2",attrs:{"dense":""},on:{"click":function($event){_vm.timeStartDialog = false}}},[_vm._v("Cancel")])]}}],null,false,2845767714),model:{value:(_vm.tempTime),callback:function ($$v) {_vm.tempTime=$$v},expression:"tempTime"}}):_vm._e()],1),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Time End","prepend-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","dense":""},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeEndDialog),callback:function ($$v) {_vm.timeEndDialog=$$v},expression:"timeEndDialog"}},[(_vm.timeEndDialog)?_c('v-time-picker',{attrs:{"format":"HH:mm","full-width":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var save = ref.save;
      var cancel = ref.cancel;
return [_c('v-btn',{staticClass:"mt-2 ml-n2",attrs:{"color":"primary"},on:{"click":_vm.saveTimeEnd}},[_vm._v("Save")]),_c('v-btn',{staticClass:"error--text mt-2",on:{"click":function($event){_vm.timeEndDialog = false}}},[_vm._v("Cancel")])]}}],null,false,746922654),model:{value:(_vm.tempTime),callback:function ($$v) {_vm.tempTime=$$v},expression:"tempTime"}}):_vm._e()],1)],1),_c('v-row',{staticClass:"d-flex justify-center p-0"},[_c('v-col',{staticClass:"pt-0 mt-n4",attrs:{"md":"11","cols":"11"}},[_c('v-switch',{staticClass:"col-md-3 col-sm-5 col-lg-6 col-12 py-4",attrs:{"label":_vm.$t('broadcasts.customMessage'),"hide-details":""},model:{value:(_vm.changeMessage),callback:function ($$v) {_vm.changeMessage=$$v},expression:"changeMessage"}}),(!_vm.changeMessage)?_c('v-autocomplete',{attrs:{"label":_vm.$t('broadcasts.messageTemplate'),"items":_vm.getMessageTemplate,"outlined":"","item-value":"id","item-text":"template_name","dense":""},model:{value:(_vm.selectedTemplate),callback:function ($$v) {_vm.selectedTemplate=$$v},expression:"selectedTemplate"}}):_vm._e()],1),_c('v-col',{staticClass:"pt-0 mt-n4",attrs:{"md":"11","cols":"11"}},[(_vm.changeMessage)?_c('message-template',{ref:"message-broadcast"}):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"mr-4",on:{"click":function($event){_vm.addScheduleDialog = false}}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('cancel')))])]),_c('v-btn',{staticClass:"mr-16",attrs:{"color":"primary"},on:{"click":function($event){return _vm.saveSchedule()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }