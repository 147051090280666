<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row class="mb-0 pb-0">
          <v-col cols="12" md="4">
            <v-btn block color="primary" @click="addScheduleDialog = true">
              <v-icon dark left>
                {{ icons.mdiPlus }}
              </v-icon>
              {{ `${$t('add')}` }} Schedule
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <div class="d-flex">
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                :label="$t('search')"
                single-line
                hide-details
                dense
                outlined
                class="me-2"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        item-key="id"
        :headers="columnsSchedule"
        :loading="loading"
        class="text-no-wrap"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
        }"
      >
        <template #[`item.messageReply`]="{ item }">
          <v-combobox chips clearable multiple outlined dense deletable-chips>
            <template v-slot:selection="{ phones, item, select, selectedPhones }">
              <v-chip
                v-bind="phones"
                clearable
                :input-value="selectedPhones"
                close
                :rules="[validators.required]"
                @click="select"
                @click:close="remove(item)"
              >
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>
        </template>

        <template #[`item.status`]="{ item }">
          <v-switch v-model="switch1"></v-switch>
        </template>

        <template #[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="openEditChatbot(item)">
                <v-icon color="#F15858">{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>Edit Chatbot</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="deleteProcess(item)">
                <v-icon color="#F15858">{{ icons.mdiTrashCanOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }} Chatbot</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- Dialog add Schedule -->
      <v-dialog v-model="addScheduleDialog" :width="changeMessage ? '1000px' : '500px'" persistent>
        <v-card>
          <v-card-title class="text-h6 grey lighten-2 font-weigth-bold"> Add Auto Replay </v-card-title>
          <v-divider />
          <v-combobox v-model="keyword" chips clearable label="Keyword" multiple outlined dense deletable-chips>
            <template v-slot:selection="{ phones, item, select, selectedPhones }">
              <v-chip
                v-bind="phones"
                clearable
                :input-value="selectedPhones"
                close
                :rules="[validators.required]"
                @click="select"
                @click:close="remove(item)"
              >
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>
          <v-card-actions class="pt-0 pb-0">
            <v-select
              :items="selectChannel"
              v-model="selectedChannel"
              return-object
              item-text="label"
              label="Channel"
              outlined
              dense
              width="400px"
            ></v-select>
          </v-card-actions>
          <v-card-actions class="ml-n8 pb-0">
            <v-menu
              ref="menu"
              v-model="timeStartDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeStart"
                  label="Time Start"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker v-if="timeStartDialog" format="HH:mm" v-model="tempTime" full-width>
                <template v-slot:default="{ save, cancel }">
                  <v-btn color="primary" class="mt-2 ml-n2" dense @click="saveTimeStart">Save</v-btn>
                  <v-btn class="error--text mt-2" dense @click="timeStartDialog = false">Cancel</v-btn>
                </template>
              </v-time-picker>
            </v-menu>
            <v-menu
              ref="menu"
              v-model="timeEndDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeEnd"
                  label="Time End"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker v-if="timeEndDialog" v-model="tempTime" format="HH:mm" full-width>
                <template v-slot:default="{ save, cancel }">
                  <v-btn color="primary" class="mt-2 ml-n2" @click="saveTimeEnd">Save</v-btn>
                  <v-btn class="error--text mt-2" @click="timeEndDialog = false">Cancel</v-btn>
                </template>
              </v-time-picker>
            </v-menu>
          </v-card-actions>

          <v-row class="d-flex justify-center p-0">
            <v-col md="11" cols="11" class="pt-0 mt-n4">
              <v-switch
                v-model="changeMessage"
                class="col-md-3 col-sm-5 col-lg-6 col-12 py-4"
                :label="$t('broadcasts.customMessage')"
                hide-details
              ></v-switch>
              <v-autocomplete
                v-if="!changeMessage"
                :label="$t('broadcasts.messageTemplate')"
                :items="getMessageTemplate"
                v-model="selectedTemplate"
                outlined
                item-value="id"
                item-text="template_name"
                dense
              ></v-autocomplete>
            </v-col>
            <v-col md="11" cols="11" class="pt-0 mt-n4">
              <message-template v-if="changeMessage" ref="message-broadcast" />
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-space-between">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="4" class="d-flex justify-space-around">
                <v-btn @click="addScheduleDialog = false" class="mr-4">
                  <span class="error--text">{{ $t('cancel') }}</span>
                </v-btn>
                <!-- </v-col>
              <v-col 
                cols="2"
              > -->
                <v-btn color="primary" class="mr-16" @click="saveSchedule()">
                  {{ $t('save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { ref } from '@vue/composition-api'
import uppyUploader from '@/components/UppyUploader.vue'
import pouchDbMixin from '@/mixins/pouchDbMixin'
import { uuid } from 'vue-uuid'
import { required } from '@core/utils/validation'
import {
  mdiAlert,
  mdiAttachment,
  mdiBroadcast,
  mdiChartMultiple,
  mdiChevronDown,
  mdiClose,
  mdiCloseCircle,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEye,
  mdiFormSelect,
  mdiInformation,
  mdiLink,
  mdiPaperclip,
  mdiPencilOutline,
  mdiPencil,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiTrashCanOutline,
} from '@mdi/js'
import messageTemplate from './MessageTemplate.vue'
import { fileManagerModal } from 'mini-file-manager'

export default {
  name: 'Schedule',
  components: { uppyUploader, messageTemplate },
  mixins: [pouchDbMixin],
  setup() {
    const tab = ref('')
    const tabs = [{ title: 'Auto Reply' }, { title: 'Auto Follow Up' }]
    return {
      icons: {
        mdiPaperclip,
        mdiFormSelect,
        mdiChevronDown,
        mdiDotsVertical,
        mdiPlus,
        mdiCloseCircle,
        mdiPencil,
        mdiDeleteOutline,
        mdiBroadcast,
        mdiEye,
        mdiContentCopy,
        mdiChartMultiple,
        mdiAlert,
        mdiLink,
        mdiAttachment,
        mdiPlusCircleOutline,
        mdiInformation,
        mdiClose,
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
      validators: {
        required,
      },
    }
  },
  data() {
    return {
      openDeleteDialog: false,
      search: '',
      loading: false,
      addScheduleDialog: false,
      selectChannel: [],
      timeStartDialog: false,
      timeEndDialog: false,
      timeStart: '',
      timeEnd: '',
      changeMessage: false,
      getMessageTemplate: [],
      tempTime: '',
      selectedChannel: {},
      selectedTemplate: '',
      keyword: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    columnsSchedule() {
      return [
        { text: 'Channel', value: 'channel' },
        { text: 'Range Time', value: 'rangeTime' },
        { text: 'List Of Reply', value: 'messageReply' },
        { text: 'Status', value: 'status' },
        { text: 'Acton', value: 'action' },
      ]
    },
  },
  async mounted() {
    //   this.loading = true
    this.fetchChannel()
    this.fetchMessageTemplate()
    // console.log('sayang', `${new Date().getHours()}:${new Date().getMinutes() < 10 && 0}${new Date().getMinutes()}`)
  },
  methods: {
    openFileManagerMsg() {
      fileManagerModal(fileManagerOptions, this.saveFile)
    },
    saveTimeStart() {
      const parent = this
      parent.timeStart = parent.tempTime
      parent.timeStartDialog = false
    },
    saveTimeEnd() {
      const parent = this
      parent.timeEnd = parent.tempTime
      parent.timeEndDialog = false
    },
    async fetchChannel(label) {
      const getChannel = await clientPocketBase.collection('crm_instances').getFullList(200, {
        filter: `broadcast_group = false`,
      })
      this.selectChannel = getChannel
    },
    async fetchMessageTemplate(label) {
      const getMessageTemplate = await clientPocketBase.collection('crm_message_templates').getFullList(200)
      this.getMessageTemplate = getMessageTemplate
    },
    async saveSchedule() {
      try {
        let savedSchedule = await clientPocketBase.collection('crm_chatbot').create({
          start_time: this.timeStart,
          end_time: this.timeEnd,
          channel: this.selectedChannel,
          sub_id: this.user.sub_id,
          message_template: this.selectedTemplate,
        })
        this.addScheduleDialog = false
      } catch (err) {
        console.log(err)
      }
    },
    saveFile(data) {
      // this.formAddTemplate.attachments.push(...data)
      // console.log(data, this.formAddTemplate, 'ini cok<')
      // const images = Promise.all(
      //   data.map(async el => {
      //     const res = 'https://fm.prod.marketa.id/uploads/' + el.uploadRelativePath
      //     this.photoLinks.push(res)
      //   }),
      // )
      data.forEach(el => {
        const res = 'https://fm.prod.marketa.id/uploads/' + el.uploadRelativePath
        el.uploadURL = res
        if (el.mimeGroup === 'text') {
          el.preview = `https://fm.prod.marketa.id/uploads/taufik/text.png?1672168938000`
        } else if (el.mimeType === 'application/pdf') {
          el.preview = `https://fm.prod.marketa.id/uploads/taufik/pdf.png?1672168938000`
        } else {
          el.preview = res
        }
      })
      this.photoLinks = [...this.photoLinks, ...data]
      this.formAddTemplate.attachments = [...this.photoLinks]
    },
  },
  watch: {
    changeMessage() {
      if (this.changeMessage) {
        this.$refs['message-broadcast'].open()
      }
    },
  },
}
</script>

<style scoped>
::v-deep .v-label {
  font-size: 1em;
}
::v-deep .v-label--active {
  font-size: 1.25em;
  font-weight: bold;
}
.v-input .v-label {
  font-size: 1em !important;
}
.v-input .v-label--active {
  font-size: 1.25em !important;
  font-weight: bold !important;
}
.mobile-col {
  display: block;
}
.width-dialog {
  max-width: 680px;
}
.height-dialog {
  height: 450px;
  overflow-y: auto;
}
.order-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}
.mobile-col-show {
  display: none;
}
.dot {
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

@media (max-width: 1100px) {
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
}
.text-area-span {
  margin-top: -90px;
}

.mobile-span {
  display: inherit;
}
.margin-start4 {
  margin-left: 16px;
}
.wrapper {
  justify-content: center;
  padding: 20px 0;
  width: auto;
  text-align: center;
}
.description {
  text-align: center;
}
.map {
  width: 80%;
  height: 300px;
  display: inline-block;
  max-height: 100%;
  overflow: auto;
  border: 2px ridge silver;
  background-color: rgb(229, 227, 223);
}

@media screen and (max-width: 500px) {
  .description {
    display: none;
  }
}
@media (max-width: 600px) {
  .margin-start4 {
    margin-left: 0px;
  }
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  .mobile-col-show {
    display: block;
  }
  .mobile-span {
    display: none;
  }
  .col-margin-top-mobile {
    margin-top: -30px;
  }
}

.additional-divider {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 32px;
  margin-right: 32px;
}
.additional-faq {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 10px;
}
@media (max-width: 960px) {
  .mobile-col {
    display: none;
  }
  .map {
    width: 100%;
    height: 300px;
    display: inline-block;
    max-height: 100%;
    overflow: auto;
    border: 2px ridge silver;
    background-color: rgb(229, 227, 223);
  }
  .additional-faq {
    border-left: 0px;
    border-top: 2px;
    border-right: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color: #afd0ff;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
  }
}
</style>
